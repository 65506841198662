import * as React from 'react'
import { SVGProps } from 'react'

const SvgIncidentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#incident-icon_svg__a)" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
      <path d="M14 10.5v2.333m0 4.667v.012M5.834 22.167h16.333a2.333 2.333 0 0 0 2.147-3.209L16.03 4.667a2.334 2.334 0 0 0-4.083 0L3.664 18.958a2.333 2.333 0 0 0 2.041 3.209" />
    </g>
    <defs>
      <clipPath id="incident-icon_svg__a">
        <path fill="#fff" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgIncidentIcon
