import { SVGProps } from 'react'

const ScoreD = (props: SVGProps<SVGSVGElement>) => (
  <svg width="40" height="44" viewBox="0 0 40 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1_26)">
      <path d="M20 1.14127L39 11.5913V32.4087L20 42.8587L1 32.4087V11.5913L20 1.14127Z" stroke="#EB5757" strokeWidth="2" />
      <path
        d="M18.9411 31H13.0263V13.5455H19.0604C20.7933 13.5455 22.282 13.8949 23.5263 14.5938C24.7763 15.2869 25.7365 16.2841 26.407 17.5852C27.0774 18.8864 27.4126 20.4432 27.4126 22.2557C27.4126 24.0739 27.0746 25.6364 26.3984 26.9432C25.728 28.25 24.7592 29.2528 23.4922 29.9517C22.2308 30.6506 20.7138 31 18.9411 31ZM16.1882 28.2642H18.7876C20.0036 28.2642 21.0178 28.0426 21.8303 27.5994C22.6428 27.1506 23.2536 26.483 23.6626 25.5966C24.0717 24.7045 24.2763 23.5909 24.2763 22.2557C24.2763 20.9205 24.0717 19.8125 23.6626 18.9318C23.2536 18.0455 22.6484 17.3835 21.8473 16.946C21.0518 16.5028 20.0632 16.2812 18.8814 16.2812H16.1882V28.2642Z"
        fill="#EB5757"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_26">
        <rect width="40" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ScoreD
