export const endpoints = {
  merchantsLogin: '/merchants/login',
  merchantsProfile: '/merchants/profile',
  merchantsSetPassword: '/merchants/set/password',
  merchantsResetPassword: '/merchants/reset/password',
  merchantsChangePassword: 'merchants/change/password',
  merchantsForgotPassword: '/merchants/forgot/password',
  insurancePlans: '/insurance/plans',
  getDocuments: '/insurance/plans/document',
  addInsurancePolicy: '/insurance/policies/add',
  addInsurancePlan: '/insurance/plans/add',
  insurancePolicies: '/insurance/policies',
  insurancePoliciesRemove: '/insurance/policies/remove',
  insurancePoliciesUpdate: '/insurance/plans/update',
  updateInsurancePlans: '/insurance/plans/update',
  toggleInsurancePolicies: '/insurance/policies/deactivate/toggle',
  toggleInsurancePlans: 'insurance/plans/deactivate/toggle',
  insuranceUserListing: '/merchants/user/listing',
  insuranceRequests: '/insurance/requests',
  registerinsuranceUser: 'merchants/user/register',
  merchantsCompany: '/merchants/company',
  merchantsAdmins: '/merchants/admins',
  addMerchantsAdmins: '/merchants/add',
  addComment: 'merchant/comments/add',
  getComment: 'merchant/comments/',
  incidentListing: 'threats/merchant/listing',
  threatsDETAIL: 'threats/merchant/detail',
  blockMerchantstoggle: '/merchants/block/toggle',
  policyParameters: 'policy/parameters/',
  resendEmail: '/merchants/registration/email/resend',
  roleToggle: '/merchants/role/toggle',
  removeComment: 'merchant/comments/remove',
  updateComment: 'merchant/comments/update',
  acceptOrRejectClaim: '/merchant/claim/request/action',
  editPremium: 'merchant/user/insurance/plans',
  getThreatChainTransaction: 'threats/chain/hash',
  renewPolicy: 'merchant/user/insurance/plans/renew',
  ResendMailToSetUserPassword: 'merchants/resend/user/set/password/email',
  ResendMailToSetAdminPassword: 'merchants/resend/merchant/set/password/email',
  ResendPaymentReminderToUsers: 'merchants/resend/user/payment/required/email',
  //cove
  getCoveIncidentLisiting: 'cove/threats/merchant/listing',
  coveThreatDetails: 'cove/threats/merchant/detail',
  acceptOrRejectClaimCove: 'merchant/cove/claim/request/action',
  //NSight
  getNSightPatchIncidentLisiting: 'patch/threats/merchant/listing',
  NSightPatchThreatDetails: 'patch/threats/merchant/detail',
  acceptOrRejectClaimNSightPatch: 'merchant/patch/claim/request/action',
  adminDeleteUser: 'merchants/user/status',
  InviteAffiliateUser: 'merchants/user/affiliate/invite ',
  MISP_API: '/misp',
}
