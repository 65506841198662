import { styled } from 'styles'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { routes } from 'configs/constants'
import { DownArrowIcon, SettingsIcon, ProfileIcon, CompanyIcon, UsersIcon } from 'components/icons'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'components/collapsible'
import clsx from 'clsx'
import useAuth from 'hooks/use-auth'
import { AdminRoles } from 'utils/enum'
import { useEffect, useState } from 'react'
import { Box } from 'components/elements'
import { HamburderProps } from 'components/icons/hamburger/hamburger'
import { BackdropFilterCSS } from './Sidebar'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'

type SidebarItem = {
  name: string
  icon: React.ReactNode
  path?: string
  disabled?: boolean
  children?: SidebarItem[]
}

const sidebarConfig: SidebarItem[] = [
  {
    name: 'Profile',
    icon: <ProfileIcon />,
    path: routes.profile,
    disabled: false,
  },
  {
    name: 'Admins',
    icon: <UsersIcon />,
    path: routes.admins,
    disabled: false,
  },
  {
    name: 'Company Details',
    icon: <CompanyIcon />,
    path: routes.companyDetails,
    disabled: false,
  },
  {
    name: 'Company Services',
    icon: <MiscellaneousServicesIcon />,
    path: routes.companyServices,
    disabled: false,
  },
]

const subSidebarConfig: SidebarItem[] = [
  {
    name: 'Profile',
    icon: <ProfileIcon />,
    path: routes.profile,
    disabled: false,
  },
]

const SubSidebarMenu = ({ toggleSidebar, sidebarToggle }: HamburderProps) => {
  const { user } = useAuth()
  const { pathname } = useLocation()
  const [sideSection, setSideSection] = useState(sidebarConfig)

  useEffect(() => {
    if (user?.role === AdminRoles.SUB) {
      setSideSection(subSidebarConfig)
    }
  }, [user])

  const getMenuItem = (entry: any, isNested = false) => (
    <div key={entry.path}>
      <MenuLink to={entry.path} className={clsx({ active: entry.path === pathname, nested: isNested })}>
        {entry.icon}
        <span>{entry.name}</span>
      </MenuLink>
    </div>
  )

  return (
    <Box css={BackdropFilterCSS} className={`subMenu ${toggleSidebar ? 'show--backdrop' : ''}`} onClick={sidebarToggle}>
      <MenuWrapper className={`${toggleSidebar ? 'show--sidebar' : ''}`}>
        <MenuList>
          {sideSection.map((entry, idx) => {
            if (!entry.children) {
              return getMenuItem(entry)
            }
            return (
              <>
                <MenuDivider />
                <Collapsible key={idx} defaultOpen={true} asChild>
                  <div>
                    <CollapsibleTrigger asChild>
                      <MenuLink as="button">
                        <SettingsIcon />
                        <span>Settings</span>
                        <DownArrowIcon fontSize={14} style={{ marginLeft: 'auto' }} />
                      </MenuLink>
                    </CollapsibleTrigger>

                    <CollapsibleContent asChild>
                      <MenuList>{entry.children.map(subEntry => getMenuItem(subEntry, true))}</MenuList>
                    </CollapsibleContent>
                  </div>
                </Collapsible>
              </>
            )
          })}
        </MenuList>
      </MenuWrapper>
    </Box>
  )
}

const MenuWrapper = styled('div', {
  'position': 'fixed',
  'left': 0,
  'display': 'inline-block',
  'width': 230,
  'borderRight': '1px solid $gray2',
  'height': 'calc(100vh - 174px)',
  'verticalAlign': 'top',
  'py': '1rem',
  'bg': '$offcanvasGradient',
  'transform': 'translateX(-100%)',
  'transition': 'transform 0.25s ease-in-out',

  '@md': {
    bg: 'none',
    position: 'absolute',
  },

  '@sm': {
    transform: 'translateX(0)',
  },

  '&.show--sidebar': {
    transform: 'translateX(0)',
  },

  '&:after': {
    'content': '',
    'display': 'block',
    'width': 'calc(100% + 1px)',
    'height': '40px',
    'position': 'absolute',
    'bottom': -40,
    'right': -1,
    'borderRight': '1px solid $gray2',
    'bg': '$offcanvasGradient',

    '@md': {
      bg: 'none',
    },
  },
})

const MenuList = styled('ul', {
  listStyle: 'none',
  padding: 0,
  margin: 0,
})

const MenuLink = styled(RouterLink, {
  'display': 'flex',
  'cursor': 'pointer',
  'border': 'none',
  'background': 'none',
  'width': '100%',
  'alignItems': 'center',
  'justifyContent': 'flex-start',
  'padding': '16px 24px 16px 34px',
  'height': 60,
  'gap': 12,
  '&.nested': {
    paddingLeft: 54,
  },
  '&.active': {
    background: '$primary',
    borderRadius: '0 6px 6px 0',
  },
  'svg': {
    fontSize: 28,
  },
  'span': {
    fontSize: 16,
    fontWeight: 500,
  },
})

const MenuDivider = styled('div', {
  height: 0.1,
  marginLeft: 35,
  backgroundColor: '$gray3',
})

export default SubSidebarMenu
