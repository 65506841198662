import { styled } from 'styles'

const StyledButton = styled('button', {
  'all': 'unset',
  'display': 'inline-flex',
  'padding': 0,
  'border': 'none',
  'alignItems': 'center',
  'justifyContent': 'center',
  'lineHeight': 1.25,
  'flexShrink': 0,
  'userSelect': 'none',
  'cursor': 'pointer',
  'flexGrow': 1,
  'minWidth': '120px',

  '@sm': {
    flexGrow: 'initial',
    minWidth: '160px',
  },

  '&:disabled': {
    opacity: 0.5,
    pointerEvents: 'none',
  },

  'variants': {
    size: {
      sm: {
        borderRadius: '$sm',
        padding: '.65rem 1.15rem',
        fontSize: '$base',
      },
      md: {
        borderRadius: '$sm',
        padding: '.88rem 1.25rem',
        fontSize: '$base',
      },
      lg: {
        borderRadius: '$sm',
        padding: '1.15rem 2.5rem',
        fontSize: '$base',
      },
    },
    capitalized: {
      true: {
        textTransform: 'uppercase',
      },
    },
    color: {
      primary: {
        'backgroundColor': '$primary',
        // boxShadow: "inset 0 0 0 1px $colors$blue7",
        'border': '1px solid $primaryBorder',
        'color': '$white',
        '&:hover': {
          boxShadow: 'inset 0 0 0 1px $colors$blue8',
        },
        '&:focus': {
          boxShadow: 'inset 0 0 0 1px $colors$blue8',
        },
        '&:active': {
          boxShadow: 'inset 0 0 0 1px $colors$blue8',
        },
      },
    },
    bordered: {
      true: {
        bgColor: 'transparent',
        boxShadow: 'none',
      },
    },
    transparent: {
      true: {
        bgColor: 'transparent',
        boxShadow: 'none',
        border: 'none',
      },
    },
    light: {
      true: {
        bgColor: 'transparent',
        border: 'none',
      },
    },
  },
  'compoundVariants': [
    {
      bordered: 'true',
      css: {
        border: '1px solid $primaryBorder',
        color: '$white',
      },
    },
    {
      transparent: 'true',
      css: {
        border: 'none',
        color: '$white',
      },
    },
    {
      light: 'true',
      css: {
        color: '$white',
      },
    },
  ],
  'defaultVariants': {
    size: 'md',
    color: 'primary',
  },
})

export const Button = StyledButton
