import { SVGProps } from 'react'

const ScoreA = (props: SVGProps<SVGSVGElement>) => (
  <svg width="40" height="44" viewBox="0 0 40 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1_12)">
      <path d="M20 1.14127L39 11.5913V32.4087L20 42.8587L1 32.4087V11.5913L20 1.14127Z" stroke="$teal" strokeWidth="2" />
      <path d="M15.2795 31H11.9045L18.0494 13.5455H21.9528L28.1062 31H24.7312L20.0692 17.125H19.9329L15.2795 31ZM15.3903 24.1562H24.5948V26.696H15.3903V24.1562Z" fill="$teal" />
    </g>
    <defs>
      <clipPath id="clip0_1_12">
        <rect width="40" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ScoreA
