import { styled } from 'styles'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  question?: string
}

export const Checkbox = ({ question }: Props) => {
  return (
    <>
      <StyledCheckbox type="checkbox" />
      <h4 className="detail extended">{question}</h4>
    </>
  )
}

const StyledCheckbox = styled('input', {})
