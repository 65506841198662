import { useQuery } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { endpoints } from 'utils/endpoints'
import { ApiKey } from '../components/enums'

export type GetIncidentsParams = {
  pageNumber?: number
  pageSize?: number
  threatStatus?: string | null
  planId?: number | string | null
  search?: string | null
  claimStatus?: string | number | null
  userId?: number | null
}

export const getIncidents = (params: GetIncidentsParams) => {
  return axios.get(endpoints.incidentListing, { params })
}

type UseIncidentsOptions = GetIncidentsParams

export const useIncidents = (options: UseIncidentsOptions = {}) => {
  return useQuery([ApiKey.incidents, ApiKey.list, options], () => getIncidents(options))
}
