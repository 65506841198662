import Axios, { AxiosRequestConfig } from 'axios'
import { API_URL } from 'configs/constants'
import storage from 'utils/storage'
import { queryClient } from './react-query'
// import { useNotificationStore } from '@/stores/notifications';

export const axios = Axios.create({
  baseURL: API_URL,
})

const authRequestInterceptor = (config: AxiosRequestConfig) => {
  const token = storage.getToken()
  const newConfig = { ...config } // Clone the config object
  if (!newConfig.headers) {
    newConfig.headers = {}
  }
  if (token) {
    newConfig.headers.authorization = `${token}`
  }
  newConfig.headers.accept = 'application/json'
  return newConfig
}

axios.interceptors.request.use(authRequestInterceptor)

axios.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    const status = error.response?.status || 200
    if (status === 401) {
      storage.clearToken()
      queryClient.clear()
    }
    return Promise.reject(error)
  }
)
