import { styled } from 'styles'

export const IconButton = styled('button', {
  'appearance': 'none',
  'display': 'inline-flex',
  'alignItems': 'center',
  'flexShrink': 0,
  'fontFamily': 'inherit',
  'justifyContent': 'center',
  'lineHeight': '1',
  'outline': 'none',
  'padding': '$2',
  'borderWidth': '0',
  'textDecoration': 'none',
  'userSelect': 'none',
  'cursor': 'pointer',
  'WebkitTapHighlightColor': 'transparent',
  'color': '$text',
  'backgroundColor': 'transparent',
  '&:hover': {
    borderRadius: '6px',
    backgroundColor: '$white1A',
  },
  '&:focus': {
    boxShadow: 'inset 0 0 0 1px $colors$slateA8, 0 0 0 1px $colors$slateA8',
  },
  '&:active': {
    backgroundColor: '$slateA4',
  },
  '&:disabled': {
    pointerEvents: 'none',
    backgroundColor: 'transparent',
    color: '$slate6',
  },
})
