import { styled } from 'styles'
import SidebarMenu from './SidebarMenu'
import { Box } from 'components/elements'
import { routes } from 'configs/constants'
import { useNavigate } from 'react-router-dom'
import { useCompany } from 'features/setting/api/getCompany'
import { HamburderProps } from 'components/icons/hamburger/hamburger'

const Sidebar = ({ toggleSidebar, sidebarToggle }: HamburderProps) => {
  const { data } = useCompany()
  const navigate = useNavigate()

  return (
    <Box css={BackdropFilterCSS} className={`${toggleSidebar ? 'show--backdrop' : ''}`} onClick={sidebarToggle}>
      <SidebarWrapper className={`${toggleSidebar ? 'show--sidebar' : ''}`}>
        <Box className="logo" onClick={() => navigate(routes.home)}>
          {data?.data.logoUri && <img src={data?.data.logoUri} alt="Profile" className="img" />}
          <Box as="h3" style={{ marginBottom: '0' }}>
            {data?.data.name || ''}
          </Box>
        </Box>
        <SidebarMenu />
      </SidebarWrapper>
    </Box>
  )
}

export const BackdropFilterCSS = {
  'position': 'fixed',
  'width': '100%',
  'height': '100vh',
  'background': '$white1A',
  'backdropFilter': 'blur(3px)',
  'opacity': 0,
  'pointerEvents': 'none',
  'transition': 'opacity .25s ease-in-out',
  'zIndex': 50,

  '&.show--backdrop': {
    pointerEvents: 'all',
    opacity: 1,
  },
  '@md': {
    pointerEvents: 'all',
    opacity: 1,
    width: 0,
  },

  '&.subMenu': {
    'zIndex': 49,

    '@sm': {
      pointerEvents: 'all',
      opacity: 1,
      width: 0,
    },
  },
}

const SidebarWrapper = styled('div', {
  'display': 'flex',
  'flexDirection': 'column',
  'minWidth': '$space$sidebarWidth',
  'width': '$space$sidebarWidth',
  'color': '$trueWhite70',
  'height': '100%',
  'bgImage': '$offcanvasGradient',
  'position': 'fixed',
  'left': 0,
  'top': 0,
  'transform': 'translateX(-100%)',
  'transition': 'transform .25s ease-in-out',

  '&.show--sidebar': {
    transform: 'translateX(0)',
  },
  '@md': {
    transform: 'translateX(0)',
  },

  '& > .logo': {
    'paddingLeft': '2rem',
    'pt': 54,
    'pb': 30,
    'cursor': 'pointer',
    'fontFamily': 'math',
    'display': 'flex',
    'alignItems': 'center',
    'gap': '20px',

    '& > .img': {
      width: '70px',
      height: '70px',
      borderRadius: '100px',
    },
  },
})

export default Sidebar
