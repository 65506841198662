import { axios } from 'lib/axios'
import { endpoints } from 'utils/endpoints'
import { useQuery } from '@tanstack/react-query'
import { ApiKey } from 'utils/enum'

export const getCompany = async () => {
  return axios.get(endpoints.merchantsCompany)
}

export const useCompany = () => {
  return useQuery([ApiKey.company, ApiKey.profile], () => getCompany())
}
