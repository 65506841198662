import * as React from 'react'
import { SVGProps } from 'react'

const SvgProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.8148 10.87C12.7148 10.86 12.5948 10.86 12.4848 10.87C10.1048 10.79 8.21484 8.84 8.21484 6.44C8.21484 3.99 10.1948 2 12.6548 2C15.1048 2 17.0948 3.99 17.0948 6.44C17.0848 8.84 15.1948 10.79 12.8148 10.87Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.815 14.56C5.395 16.18 5.395 18.82 7.815 20.43C10.565 22.27 15.075 22.27 17.825 20.43C20.245 18.81 20.245 16.17 17.825 14.56C15.085 12.73 10.575 12.73 7.815 14.56Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgProfileIcon
