import { styled } from 'styles'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { routes } from 'configs/constants'
import { DownArrowIcon, IncidentIcon, SettingsIcon, InsuranceIcon, UsersIcon } from 'components/icons'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'components/collapsible'
import clsx from 'clsx'
import { Box } from 'components/elements'
import { useIncidents } from 'features/incidents/api/getIncidents'
import { UseCoveThreatLisiting } from 'features/incidents/api/getCoveThreat'
import { UseNSightPatchThreatLisiting } from 'features/incidents/api/getNSightPatchThreat'

type SidebarItem = {
  name: string
  icon: React.ReactNode
  path?: string
  disabled?: boolean
  children?: SidebarItem[]
  notificationCount?: number
}

const sidebarConfig: SidebarItem[] = [
  {
    name: 'Policy Holders',
    icon: <UsersIcon />,
    path: routes.users,
    disabled: false,
  },
  {
    name: ' Policies',
    icon: <InsuranceIcon />,
    path: routes.insurancePlans,
    disabled: false,
  },
  {
    name: 'Incidents',
    icon: <IncidentIcon />,
    path: routes.incidents,
    disabled: false,
    notificationCount: 76,
  },
]

const SidebarMenu = () => {
  const { pathname } = useLocation()
  const { data: incident } = useIncidents({
    pageNumber: 0,
    pageSize: 1,
    claimStatus: 'Claim Raised',
  })
  const { data: cove } = UseCoveThreatLisiting({
    pageNumber: 0,
    pageSize: 1,
    claimStatus: 'Claim Raised',
  })
  const { data: nsight } = UseNSightPatchThreatLisiting({ pageNumber: 0, pageSize: 1, claimStatus: 'Claim Raised' })

  const potentialClaim = incident?.data?.count + cove?.data?.count + nsight?.data?.count

  const getMenuItem = (entry: any, isNested = false) => (
    <div key={entry.path}>
      <MenuLink to={entry.path} className={clsx({ active: entry.path === pathname, nested: isNested })}>
        {entry.icon}
        <span>{entry.name}</span>
        {!!entry.notificationCount && (
          <>
            {potentialClaim > 0 && (
              <NotificationNumber>
                <Box as="p" css={{ m: 0 }}>
                  {potentialClaim}
                </Box>
              </NotificationNumber>
            )}
          </>
        )}
      </MenuLink>
    </div>
  )

  return (
    <MenuWrapper>
      <MenuList>
        {sidebarConfig.map((entry, idx) => {
          if (!entry.children) {
            return getMenuItem(entry)
          }
          return (
            <>
              <MenuDivider />
              <Collapsible key={idx} defaultOpen={true} asChild>
                <div>
                  <CollapsibleTrigger asChild>
                    <MenuLink as="button">
                      <SettingsIcon />
                      <span>Settings</span>
                      <DownArrowIcon fontSize={14} style={{ marginLeft: 'auto' }} />
                    </MenuLink>
                  </CollapsibleTrigger>

                  <CollapsibleContent asChild>
                    <MenuList>{entry.children.map(subEntry => getMenuItem(subEntry, true))}</MenuList>
                  </CollapsibleContent>
                </div>
              </Collapsible>
            </>
          )
        })}
      </MenuList>
    </MenuWrapper>
  )
}

const MenuWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  pt: 30,
  pb: 20,
})

const NotificationNumber = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '2.5rem',
  height: '2.5rem',
  p: '.5rem',
  background: '$red',
  borderRadius: '50%',
})

const MenuList = styled('ul', {
  listStyle: 'none',
  padding: 0,
  margin: 0,
})

const MenuLink = styled(RouterLink, {
  'display': 'flex',
  'cursor': 'pointer',
  'border': 'none',
  'background': 'none',
  'width': '100%',
  'alignItems': 'center',
  'justifyContent': 'flex-start',
  'padding': '16px 24px 16px 34px',
  'height': 60,
  'gap': 12,
  '&.nested': {
    paddingLeft: 54,
  },
  '&.active': {
    background: '$primary',
    borderRadius: '0 6px 6px 0',
  },
  'svg': {
    fontSize: 28,
  },
  'span': {
    fontSize: 16,
    fontWeight: 500,
  },
})

const MenuDivider = styled('div', {
  height: 0.1,
  marginLeft: 35,
  backgroundColor: '$gray3',
})

export default SidebarMenu
