import { keyframes, styled } from 'styles/stitches.config'
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'

const open = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-collapsible-content-height)' },
})

const close = keyframes({
  from: { height: 'var(--radix-collapsible-content-height)' },
  to: { height: 0 },
})

const StyledCollapsible = styled(CollapsiblePrimitive.Root, {})

const StyledCollapsibleContent = styled(CollapsiblePrimitive.Content, {
  'overflow': 'hidden',
  '&[data-state="open"]': { animation: `${open} 300ms ease-out` },
  '&[data-state="closed"]': { animation: `${close} 300ms ease-out` },
})

// Exports
export const Collapsible = StyledCollapsible
export const CollapsibleTrigger = CollapsiblePrimitive.Trigger
export const CollapsibleContent = StyledCollapsibleContent
