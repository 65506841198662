import { IconButton } from 'components/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from 'components/dropdown'
import { Box } from 'components/elements'
import { DownArrowIcon, SettingsIcon, LogoutIcon } from 'components/icons'
import { routes } from 'configs/constants'
import useAuth from 'hooks/use-auth'
import { Link } from 'react-router-dom'

const HeaderUserbox = () => {
  const { logout, user } = useAuth()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <IconButton className="user-box-btn" css={{ '&:hover': { bgColor: 'transparent' } }}>
          <Box
            as="p"
            css={{
              fontWeight: '$bold',
              fontFamily: '$grotesk',
              m: '0',
              textTransform: 'capitalize',
            }}
          >
            {user?.firstName || 'Carrier  Admin'}
          </Box>
          {/* <Avatar imgUrl="https://i.pravatar.cc/300?u=a042581f4e290267072" /> */}
          <Box css={{ ml: 8 }}>
            <DownArrowIcon fontSize={16} />
          </Box>
        </IconButton>
      </DropdownMenuTrigger>

      <DropdownMenuContent sideOffset={10} align="end">
        <DropdownMenuItem asChild>
          <Link to={routes.profile}>
            <SettingsIcon /> Settings
          </Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={logout}>
          <LogoutIcon /> Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

type AvatarProps = {
  imgUrl: string
  content?: string
}

export const Avatar = ({ imgUrl }: AvatarProps) => {
  return (
    <Box
      css={{
        position: 'relative',
        display: 'inline-flex',
        verticalAlign: 'middle',
      }}
    >
      <Box
        css={{
          size: '2.3rem',
          minHeight: '2.3rem',
          minWidth: '2.3rem',
          outline: 'currentcolor none medium',
          dflex: 'center',
          cursor: 'auto',
          position: 'relative',
          borderRadius: '$rounded',
        }}
      >
        <Box
          as="img"
          src={imgUrl}
          css={{
            zIndex: 3,
            display: 'flex',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '$rounded',
          }}
        />
      </Box>

      <Box
        css={{
          position: 'absolute',
          top: '85%',
          right: '10%',
          transform: 'translate(50%, -50%)',
          transformOrigin: '100% 0% 0px',
          bgColor: '$success500',
          border: '2px solid $white',
          borderRadius: '$rounded',
          zIndex: 3,
          width: '0.8rem',
          height: '0.8rem',
        }}
      ></Box>
    </Box>
  )
}

export default HeaderUserbox
