import { axios } from 'lib/axios'
import { endpoints } from 'utils/endpoints'
import { GetIncidentsParams } from './getIncidents'
import { useQuery } from '@tanstack/react-query'
import { ApiKey } from '../components/enums'

export const getCoveThreatLisiting = (params: GetIncidentsParams) => {
  return axios.get(endpoints.getCoveIncidentLisiting, { params })
}
export const UseCoveThreatLisiting = (params: GetIncidentsParams = {}) => {
  return useQuery([ApiKey.CoveLisiting, ApiKey.list, params], () => getCoveThreatLisiting(params))
}
