import { ClaimStatus, ServicesAssessmentsEnum, ServicesEnum } from 'utils/enum'

export const NODE_ENV = process.env.REACT_APP_NODE_ENV
export const API_URL = process.env.REACT_APP_API_URL
export const POLY_EXPLORER_URL = process.env.REACT_APP_EXPLORER_URL
export const PLOYGON_AMOY_RPC_URL = process.env.REACT_APP_PLOYGON_AMOY_RPC_URL
export const Contract_Address = process.env.REACT_APP_CONTRACT_ADDRESS
export const Chain_Id = Number(process.env.REACT_APP_CHAIN_ID)
export const USER_AFFILIATELINK = process.env.REACT_APP_AFFILIATE_LINK

export const routes = {
  // general
  home: '/',
  about: '/about',
  security: '/security',
  // auth
  login: '/login',
  register: '/register',
  reset: '/reset-password',
  forgot: '/forgot-password',
  // dashboard
  dashboard: '/app',
  incidents: '/app/incidents',
  users: '/app/users',
  insurancePlans: '/app/insurance-plans',
  addInsurancePlan: '/app/insurance-plans/add-plan',
  addInsurancePolicy: '/app/insurance-plans/add-policy',
  updateInsurancePolicy: '/app/insurance-plans/update-policy',
  addUser: '/app/users/add-user',
  editProfile: '/app/profile/edit',
  changePass: '/app/profile/change',
  // setting
  profile: '/app/setting/profile',
  admins: '/app/setting/admins',
  addAdmin: '/app/setting/add-admin',
  companyDetails: '/app/setting/company-details',
  resendVerificationLink: '/auth/resend-verification-link',
  companyServices: '/app/setting/company-services',
}

export const steps = ['Incident Detected', 'Verifiying Incident', 'Initiate Claim', 'Insurance Confirmation', 'Resolved']
export const textArray = ['EDR', 'Cove Data Backup', 'N-Sight Patch']
export const ClaimStatusList = [
  {
    label: ClaimStatus.ALL,
    value: '',
  },
  {
    label: ClaimStatus.APPLICABLE,
    value: ClaimStatus.APPLICABLE,
  },
  {
    label: ClaimStatus.NOT_APPLICABLE,
    value: ClaimStatus.NOT_APPLICABLE,
  },
  {
    label: ClaimStatus.CLAIM_RAISED,
    value: ClaimStatus.CLAIM_RAISED,
  },
  {
    label: ClaimStatus.APPROVED,
    value: ClaimStatus.APPROVED,
  },
  {
    label: ClaimStatus.DISAPPROVED,
    value: ClaimStatus.DISAPPROVED,
  },
  {
    label: ClaimStatus.ACCEPTED,
    value: ClaimStatus.ACCEPTED,
  },
  {
    label: ClaimStatus.REJECTED,
    value: ClaimStatus.REJECTED,
  },
  {
    label: ClaimStatus.RE_EVALUATE,
    value: ClaimStatus.RE_EVALUATE,
  },
]
export const serviceArray = [ServicesEnum.EDR, ServicesEnum.COVE_DATA, ServicesEnum.N_SIGHT_AV, ServicesEnum.N_SIGHT_PATCH]
export const ServicesAssessments = {
  'Backup': [ServicesAssessmentsEnum.COVE_DATA],
  'Anti-Virus or EDR': [ServicesAssessmentsEnum.EDR, ServicesAssessmentsEnum.Crowd_Strike, ServicesAssessmentsEnum.Malware_Bytes],
  'Patch Management': [ServicesAssessmentsEnum.N_SIGHT_PATCH, ServicesAssessmentsEnum.Bitdefender_Gravity, ServicesAssessmentsEnum.Windows_Defender],
}
