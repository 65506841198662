import { SVGProps } from 'react'

const SvgInsuranceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.49 2.23L5.5 4.09999C4.35 4.52999 3.41 5.88998 3.41 7.11998V14.55C3.41 15.73 4.19001 17.28 5.14001 17.99L9.44 21.2C10.85 22.26 13.17 22.26 14.58 21.2L18.88 17.99C19.83 17.28 20.61 15.73 20.61 14.55V7.11998C20.61 5.88998 19.67 4.52999 18.52 4.09999L13.53 2.23C12.68 1.92 11.32 1.92 10.49 2.23Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9997 10.92C11.9597 10.92 11.9097 10.92 11.8697 10.92C10.9297 10.89 10.1797 10.11 10.1797 9.16C10.1797 8.19 10.9697 7.39999 11.9397 7.39999C12.9097 7.39999 13.6997 8.19 13.6997 9.16C13.6897 10.12 12.9397 10.89 11.9997 10.92Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.01 13.72C9.05001 14.36 9.05001 15.41 10.01 16.05C11.1 16.78 12.89 16.78 13.98 16.05C14.94 15.41 14.94 14.36 13.98 13.72C12.9 12.99 11.11 12.99 10.01 13.72Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgInsuranceIcon
