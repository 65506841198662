import { createContext, useMemo, useState } from 'react'
import { useQuery, useMutation, useQueryClient, QueryKey, QueryStatus } from '@tanstack/react-query'
import { loginWithEmailAndPassword as loginFn, logout as logoutFn, register as registerFn, getUserIdentity } from 'lib/auth'
import { User } from 'features/auth/types'
import { AxiosError } from 'axios'
import { AlertKeyEnum } from 'utils/enum'

// TODO: improve types
interface AuthContextValue {
  user: User | null | undefined
  authStatus: QueryStatus
  error: unknown
  refetchUser: () => void
  login: (...arg: any) => void
  isLoggingIn: boolean
  logout: () => void
  isLoggingOut: boolean
  register: (...arg: any) => void
  isRegistering: boolean
  isRegisterError: boolean
  registerError: string
}

export const AuthContext = createContext<AuthContextValue | null>(null)
AuthContext.displayName = 'AuthContext'

export interface AuthProviderProps {
  children: React.ReactNode
  authKey?: QueryKey
  waitInitial?: boolean
}

export const AuthProvider = (props: AuthProviderProps) => {
  const { children, authKey = ['auth-user'], waitInitial = true } = props
  // const [user, setUser] = useState<User | null>(null);
  const queryClient = useQueryClient()

  const [registerApiError, setRegisterApiError] = useState<string>('')

  const { data: user, error, status, fetchStatus, refetch } = useQuery(authKey, getUserIdentity)

  const loginMutation = useMutation(loginFn, {
    onSuccess: user => {
      // setUser(user);
      queryClient.setQueryData(authKey, user)
    },
  })

  const { mutateAsync, isLoading, isError } = useMutation(registerFn, {
    onError: error => {
      if (error instanceof AxiosError) {
        setRegisterApiError(error.response?.data.message)
      } else {
        setRegisterApiError(AlertKeyEnum.DEFAULT)
      }
    },
  })

  const logoutMutation = useMutation(logoutFn, {
    onSuccess: () => {
      queryClient.clear()
      // setUser(null);
    },
  })

  const value = useMemo(
    () => ({
      user,
      authStatus: status,
      error,
      refetchUser: refetch,
      login: loginMutation.mutateAsync,
      isLoggingIn: loginMutation.isLoading,
      logout: logoutMutation.mutateAsync,
      isLoggingOut: logoutMutation.isLoading,
      register: mutateAsync,
      isRegistering: isLoading,
      isRegisterError: isError,
      registerError: registerApiError,
    }),
    [
      user,
      status,
      error,
      refetch,
      loginMutation.mutateAsync,
      loginMutation.isLoading,
      logoutMutation.mutateAsync,
      logoutMutation.isLoading,
      mutateAsync,
      isLoading,
      isError,
      registerApiError,
    ]
  )

  if (status === 'success' || !waitInitial) {
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  }

  if (status === 'loading' || fetchStatus === 'idle') {
    return <div>Loading...</div>
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.log('AuthContext', error)
    return <div>Something went wrong</div>
  }

  return <div>Unhandled status: {status}</div>
}
