import { axios } from './axios'
import storage from 'utils/storage'
import { endpoints } from 'utils/endpoints'
import { LoginResponse, User } from 'features/auth/types'

export type SentinelKeyDTO = {
  sentinelOneApiKey?: string
}

export type RegisterDTO = {
  email: string
  password: string
  confirmPassword: string
  sentinelOneApiKey?: string
  companyName?: string
  companyAddress?: string
  companyWebsite?: string
}

export const register = async (data: RegisterDTO) => {
  return axios.post('/user/register', data)
}

export type LoginCredentialsDTO = {
  email: string
  password: string
}

export const loginWithEmailAndPassword = async (data: LoginCredentialsDTO): Promise<User> => {
  const resp = await axios.post<LoginResponse>(endpoints.merchantsLogin, data)
  storage.setToken(resp.data.jwtToken)
  return resp.data.user
}

export const logout = async () => {
  storage.clearToken()
}

export const getUserIdentity = async (): Promise<User> => {
  let user = null
  if (storage.getToken()) {
    const resp = await axios.get(endpoints.merchantsProfile)
    user = resp.data
  }
  return user
}

type ResetPasswordDTO = {
  jwt: string
  password: string
  confirmPassword: string
}

export const resetPassword = (data: ResetPasswordDTO): Promise<any> => {
  return axios.post(endpoints.merchantsResetPassword, data)
}

type UpdatePasswordDTO = {
  oldPassword: string
  password: string
  confirmPassword: string
}

export const updatePassword = (data: UpdatePasswordDTO): Promise<any> => {
  return axios.post(endpoints.merchantsChangePassword, data)
}
