import { Outlet } from 'react-router-dom'

import Sidebar from './Sidebar'
import Header from './Header'
import { Box } from 'components/elements'
import { useState } from 'react'

export const SidebarLayout = () => {
  const [toggleSidebar, setToggleSidebar] = useState<boolean>(false)

  const sidebarToggle = () => {
    setToggleSidebar?.(!toggleSidebar)
  }

  return (
    <Box>
      <Header setToggleSidebar={setToggleSidebar} toggleSidebar={toggleSidebar} sidebarToggle={sidebarToggle} />
      <Sidebar toggleSidebar={toggleSidebar} sidebarToggle={sidebarToggle} />
      <Box
        css={{
          'flex': 1,
          'pt': '$headerHeight',
          'pb': 40,
          '@md': {
            ml: '$sidebarWidth',
          },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}
