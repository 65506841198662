import { axios } from 'lib/axios'
import { endpoints } from 'utils/endpoints'
import { GetIncidentsParams } from './getIncidents'
import { useQuery } from '@tanstack/react-query'
import { ApiKey } from '../components/enums'

export const getNSightPatchThreatLisiting = (params: GetIncidentsParams) => {
  return axios.get(endpoints.getNSightPatchIncidentLisiting, { params })
}
export const UseNSightPatchThreatLisiting = (params: GetIncidentsParams = {}) => {
  return useQuery([ApiKey.NSightPatchLisiting, ApiKey.list, params], () => getNSightPatchThreatLisiting(params))
}
