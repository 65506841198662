import * as React from 'react'
import { SVGProps } from 'react'

const SvgUpArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#up-arrow-icon_svg__a)">
      <path d="m15 12.5-5-5-5 5" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="up-arrow-icon_svg__a">
        <path fill="#fff" transform="rotate(180 10 10)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgUpArrowIcon
