import * as React from 'react'
import { SVGProps } from 'react'

const SvgLogoutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#logout-icon_svg__a)" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
      <path d="M16.333 9.333V7A2.333 2.333 0 0 0 14 4.667H5.833A2.333 2.333 0 0 0 3.5 7v14a2.333 2.333 0 0 0 2.333 2.333H14A2.333 2.333 0 0 0 16.333 21v-2.333" />
      <path d="M8.166 14h16.333L21 10.5m0 7 3.5-3.5" />
    </g>
    <defs>
      <clipPath id="logout-icon_svg__a">
        <path fill="#fff" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgLogoutIcon
